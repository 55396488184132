@import './bootstrap.scss';
@import '~react-datepicker/dist/react-datepicker.css';
@import '~react-circular-progressbar/dist/styles.css';
@import '~react-quill/dist/quill.snow.css';
@import 'fonts.scss';

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Hind-Regular', sans-serif !important;
  -webkit-font-smoothing: subpixel-antialiased !important;
  text-rendering:optimizeLegibility !important;
  color: #55565C;
  background: #FFFFFF;
  max-width: 100%;
  overflow-x: hidden;
}

h1 {
  font-family: 'Hind-Bold';
  font-weight: 600;
  font-size: 2.0rem;
  padding: 0rem 1rem;
  margin-top: 1rem;
}
h5 {
  font-weight: bold;
}
table {
  font-size: 90%;
}

small {
  font-weight: 400;
  font-size: 80%;
}

.ql-editor ol li {
  list-style-type: none;
}
.ql-editor ol li:before {
  content: none;
}
.dropdown-item a {
  text-decoration: none;
  padding-left: 0.5rem;
}

.ql-editor {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
text-decoration: none;
}

.opacity-50 {
  opacity: 0.5;
}

.navbar-light .navbar-nav .nav-link {
  font-weight: bold;
  color: #55565C;
}
.navbar-light .navbar-nav .nav-link.active {
  color: $primary;
}

.breadcrumb {
  background-color: transparent !important;
}
.breadcrumb-item.active {
  color: $primary;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.2rem;
}
.breadcrumb-item + .breadcrumb-item::before  {
  padding-right: 0.2rem;
}

p-default {
  padding-left: 15px;
  padding-right: 15px;
}

.react-datepicker__time-container, .react-datepicker__time-box, .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 140px;
}

.filter-search {
  height: 58px;
  border: 1px solid $primary;
  border-radius: 100rem;
  input {
    border:none;
    margin: 0.35rem 0.5rem ;
  }
  button {
    background: none;
    border: none;
  }
}

.filter-select {
  .select__control {
    border-color: $primary;
    border-radius: 100rem;
    height: 58px;
    padding-left: 5px !important;
  }
  .select__indicator-separator {
    background: none !important;
  }
}

.filter-select50 {
  .select__control {
    border-color: $primary;
    border-radius: 100rem;
    height: 50px;
  }
  .select__indicator-separator {
    background: none !important;
  }
}

.custom-filter-select {
  .select__value-container {
    flex-wrap: nowrap !important;
    margin-left:  88px !important;
  }
  .select__control {
    border-color: $primary;
    border-radius: 100rem;
    height: 58px;
  }
  .select__indicator-separator {
    background: none !important;
  }
}

.table-header {
  color: $white;
  font-weight: bold;
}

.patient-header {
  font-size: 90%;
}

.clinicard {
  position: relative;
  margin-top: 20px;
  padding-top: 15px;
  margin-bottom: 20px;
  .iconbg {
    position: absolute;
    top: -18px;
    left: 20px;
  }
  .icon {
    position: absolute;
    top: -24px;
    left: 22px;
  }
  .btn {
    border-radius: 0px;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .iconinfo {
    position: absolute;
    right: 20px;
    border: none;
    background-color: transparent;
    outline: none;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .card-title {
    font-size: 1.3rem;
  }
}

.patient-table-data {
  font-size: 90%;
  th, td {
    padding: 0.4rem;
    padding-right: 1.0rem;
  }
}


.patient-nav {
  .nav-item {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  a {
    color: #55565C;
  }
  a.active {
    color: $primary;
    font-weight: bold;
    border-bottom: 2px solid $primary;
  }
}

.timeline {
  min-height: 80px;
  font-weight: bold;
  font-size: 90%;
  .date-col {
    min-height: 80px;
    background: url('../assets/images/line_blue_dot.png') no-repeat right center;
  }
  .date-col-green {
    background: url('../assets/images/line_green_dot.png') no-repeat right center !important;
  }
  .date-col-orange {
    background: url('../assets/images/line_orange_dot.png') no-repeat right center !important;
  }
}

.timeline-heading {
  background-color: $primary;
  color: $white;
  font-weight: bold;
  font-size: 90%;
}

.timeline-no-data {
  background: #EDF4FB;
}

.clinicards-result-row {
  min-height: 80px;
  // font-weight: bold;
  font-size: 90%;
  .date-col span {
    background-color: $primary;
    color: $white;
    border-radius: 100px;
    padding: 0.5rem 1.0rem;
  }
  .image-col img {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 100px;
  }
  .icons img {
    margin: 0 0.2rem;
  }
}

.tableItmBg {
  min-height: 80px;
  font-size: 90%;
  .date-col span {
    color: $white;
    border-radius: 100px;
    padding: 0.5rem 1.0rem;
  }
  .image-col img {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 100px;
  }
  .icons img {
    margin: 0 0.2rem;
  }
  td {
    background-color: #7f7f7f !important;
  }
}

.modal-dialog {
  .btn {
    border-radius: 0px;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
  }
}

.spinner-center {
  width: 30px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: auto !important;
  margin-right: auto !important;
}

.upload-image {
  position: relative;
  margin-top: 1rem;
  img {
    max-width: 200px;
  }
  svg {
    position: absolute;
    top:-5px;
    left:-5px;
    cursor: pointer;
  }
}

.timeline-list {
  border: 0 !important;
}

.disconnect {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 50px;
  height: 50px;
  z-index: 99;
  margin: 0px;
  padding: 0px;
  img {
    width: 50px;
    height: 50px;
  }
}
.video-overlay {
  position : fixed !important;
  top: 20px;
  right: 20px;
  background: #000;
  border-radius: 10px;
  margin: 0px;
  padding: 0px;
}
.participant {
  video {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: contain;
    margin: 0px;
    padding: 0px;
  }
}
.local {
  video {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 120px !important;
    height: 80px !important;

  }
}
.form-signin {
  width: 340px;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 150px;
}

.buttonlikeshape {
  background-color: #88B3E0;
  color: white;
  border-color: #6099d5;
  white-space: nowrap;
  border-radius: 100rem;
  flex-grow: 0;
  min-width: unset;
}

.recoverdot {
  color: #199BD9;
}
.recoverdotyou {
  color: #2ECC71;
  padding-left: 0.2rem;
}
.recoverprogress {
  position: absolute;
  top: 0px;
  right: 10px;
  height: 110px;
  width: 110px;
}
.recoverprogressyou {
  position: absolute;
  top: 14px;
  right: 24px;
  height: 82px;
  width: 82px;
}

.ul-menu-sub {
  list-style: none;
  margin: 0;
  padding: 0;
}

.btn-language, .btn-language:hover, .btn-language:focus, .btn-language.focus, .btn-language:not(:disabled):not(.disabled):active, .btn-language:not(:disabled):not(.disabled).active, .show > .btn-language.dropdown-toggle {
  background-color: #fff;
  border: none;
  color: #55565C;
  box-shadow: none !important
}

.bg-danger {
  background-color: #dc3545;
}

.language {
  color: $primary;
  width: 200px;
}

.language small {
  color:  #55565C;
  font-style: italic;
}

.color-picker-button {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-right: 5px;
}

.carpath-left {
  margin-left: 1.2%;
}

.cancel-btn {
  width: 14%
}

.quill {
  height: auto !important
}

.fontColor {
  color:  #55565C !important;
}
.disabledFontColor{
  color: #cbcbcd !important;
}

.py-35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.insight-style {
  height: 92% !important;
  justify-content: center;
}

.form_check_box  input[type="checkbox"],
.dropdown-checkbox
  input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  padding: 10px;
  border-color: #3a539b;
  }

.checkbox {
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 2px #3a539b solid;
  margin-right: 4px;
  padding: 1.5px;
}

.checkbox--active {
  border-color: #3a539b;
  background: #3a539b;
}
.carepath_capital{
  position: relative;
  top: -41px;
  margin-left: 23px;
  margin-right: 10px;
  max-width: 87px;
}

.filter-calender{
  height: 58px;
  border: 1px solid $primary;
  border-radius: 100rem;
  input {
    border:none;
    margin: 0.35rem 0.5rem ;
  }
  button {
    background: none;
    border: none;
  }
}

.range--datepicker{
  height: 58px;
  border: 1px solid $primary;
  border-radius: 100rem;
  input {
    border:none;
    margin: 0.35rem 0.5rem ;
  }
  button {
    background: none;
    border: none;
  }
}
.react-datepicker-wrapper{
  width: 95%;
}
a.dropdownitem{
  color: #57585e !important;
  display: block;
  width: 100%;
  padding: 0.10rem 1.1rem !important;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: initial;
  border: 0;
}

.circle-container {
  display: flex;
}

.circle {
  width: 50px; /* Adjust the size of the circle */
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  /* Add background color */
  background-color: #EFEFEF; /* You can change this color code */
}

.circle-image {
  width: 35px; /* Adjust the width of the image */
  height: 35px; /* Adjust the height of the image */
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

.empty-image {
  height: 80px;
  margin-top: 1rem;
}

.empty-text-bold {
  text-align: center;
  color: #55565C;
  font-weight: bold;
}

.empty-text {
  text-align: center;
  color: #55565C;
}

.divider {
  border: 1px solid #DBDBDB; 
  margin: 20px 0; 
}

.empty-insigth {
  height: 190px;
  display: flex;
  align-items: center;
}

.h-260{
  height: 260px !important;
}

.h-400{
  height: 400px !important;
}

.dropdown-Menu {
  right: auto !important;
  left: 8px !important
}

.scrolling-wrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}
.milestone-item {
  transition: transform 0.2s ease;
  display: inline-block;
  width: 180px;
  height: 220px;
  vertical-align: top;
}
.milestone-item:hover {
  transform: translateY(-5px);
}

.roundedCircle {
  border-radius: 50% !important;
  border-width: 4px !important;
  width: 140px;
  height: 140px;
  align-items: center;
  margin-bottom: 10px;
  display: grid;
  justify-self: center;
  justify-items: center;
  align-content: center;
}

.blueColor {
  background-color: #122a731b;
}