// Override defaults: https://getbootstrap.com/docs/4.0/getting-started/theming/
$blue: #88B3E0;
$theme-colors: (
  "primary": #88B3E0,
);

@import '~bootstrap/scss/bootstrap.scss';

.articleTableRow:hover {
  background-color: #F8F8F8;
  cursor: pointer;
}

.btn {
  white-space: nowrap;
}

.btn-primary, .btn-danger {
  color: $white;
  padding: 0.75rem 0.75rem;
  border-radius: 100rem;
  font-weight: bold;
}

.btn-danger {
  background-color: #dc3545;
}

.btn-sucess {
  background-color: #2ECC71 !important;
  border-radius: 5px !important;
  color: #fff !important;
}

.btn-cancel {
  background-color: #55565C !important;
  border-radius: 5px !important;
  color: #fff !important;
  border-color: #55565C !important;
}

.btn-secondary-danger {
  color: #dc3545;
  padding: 0.75rem 0.75rem;
  border-radius: 100rem;
  font-weight: bold;
  background-color: $white;
  border-color: #dc3545;
}

.btn-secondary-danger:hover {
  color: $white;
  background-color: #dc3545;
}

.btn-secondary-accept {
  color: #2ECC71;
  padding: 0.75rem 0.75rem;
  border-radius: 100rem;
  font-weight: bold;
  background-color: $white;
  border-color: #2ECC71;
}

.btn-secondary-accep:hover {
  color: $white;
  background-color: #2ECC71;
}

.btn-primary:hover, .btn-outline-primary:hover {
  color: $white;
}

.btn-primary.disabled, .btn-primary:disabled {
  color: white;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #6099d5;
  border-color: #5593d3;
}

.btn-outline-primary {
  padding: 0.75rem 1.5rem;
  border-radius: 100rem;
  font-weight: bold;
}

.btn-outline-primary:hover {
  border-color: #7A96BB;
  background-color: #FFFFFF;
  color:#7A96BB;
}

.btn-primary:hover {
  background-color: #7A96BB ;
  border-color: #7A96BB;
  color: #FFFFFF;
}

.btn-danger:hover {
  background-color: #C93D2E; 
  border-color: #C93D2E;
  color: #FFFFFF;
}



.form-control2 {
  resize: none; 
  display:block; 
  width:100%; 
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.invalid-feedback, .valid-feedback {
  display: inline !important;
}

.table .table-dark th {
  background-color: $primary;
  border-color: $primary;
}

.rounded-pill {
  font-size: 100% !important;
}

.col3andhalf {
  width: 30.33333%;
}

.paddingLeft-Initial{
  padding-left: initial !important
}

.amplify-box {
  border-style:none !important
}

.amplify-button[data-variation='primary'] {
  border-radius: 25px;
  width: 280px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.bg-white {
  background: #FFFFFF !important;
  height: fit-content;
}

.alerts-danger {
  --bs-alert-color: #842028;
  --bs-alert-bg: #f8d7da;
  --bs-alert-border-color: #f5c2c7;
  --bs-alert-link-color: #58151c;
  padding-block: 5px;
  min-width: 60%;
  max-width: fit-content;
  margin-bottom: 0px;
}

.width14 {
  width: 14% !important;
}

.newH1 {
  font-weight: 600 !important;
  font-size: 2rem !important;
  padding: 0rem 1rem !important;
  margin-top: 1rem !important;
  color: rgb(85, 86, 92) !important;
  font-family: "Hind", sans-serif !important;
}

.cirlceImage {
  width: 118px;
  height: 118px !important;
  object-fit: cover !important;
  max-width: 118px !important;
}

.chooseFilebtn {
  border: 1px solid rgb(127, 113, 113);
  background-color: #EEEEEF;
  padding: 3px 8px;
  height: 28px;
  border-radius: 3px; 
}

.chooseFilebtn:hover {
  background-color: #dddde0;
}